@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap);
body {
  background-color: #eaeaea !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.inline-errormsg {
  color: #dc3545;
  font-size: 12px;
}

.hidden {
  display: none;
}

.selected {
  background-color: lightblue;
}

.MuiButton-label {
  font-family: "Montserrat", sans-serif !important;
}

.home-link {
  color: #fff;
  font-weight: bold;
  font-feature-settings: "smcp";
  font-variant: small-caps;
  text-shadow: 4px 1px 5px rgb(0 0 0 / 30%);
}

.home-link:hover {
  color: #fff;
  text-decoration: none !important;
}

.main-wrapper {
  position: relative;
  padding: 0 0 60px;
  min-height: 100vh;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: #3f51b5;
  padding: 20px 0px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
}

.backBtn {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 50%;
  cursor: pointer;
}

.makeLink {
  color: rgb(36, 128, 248);
  cursor: pointer;
}
.makeLink:hover {
  text-decoration: underline;
}

.border_black {
  border: 1px solid black;
}

.makeTextFromA {
  color: white;
  text-decoration: none;
}
.makeTextFromA:hover {
  color: white;
  text-decoration: none;
}

.borderSuccess {
  border: 2px solid #5af13e !important;
}

.borderDanger {
  border: 2px solid #ff4a4a !important;
}

.wrapText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.custom_card {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  background-color: white;
}

.b_radius {
  border-radius: 4px !important;
}

.c-pointer {
  cursor: pointer;
}

.border-primary {
  border: 2px solid #3f51b5 !important;
}

.col_divider {
  border-right: 0.1px solid #e6dddd;
}

::-webkit-scrollbar {
  width: 10px !important;
  background-color: #3f51b5;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.3125rem;
  background-color: rgb(196, 196, 196);
  border: 2px solid #3f51b5;
}

.infoIcon {
  height: 13px !important;
  cursor: pointer;
  margin-top: 5px;
}

.borderRight {
  border-right: 1px solid #d8d8d8;
}

.search-bar {
  border: 2px solid #3f51b5;
  border-radius: 6px;
}

.tableLayoutF {
  table-layout: fixed !important;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #3f51b5;
  box-shadow: 0 0 0 1px #3f51b5;
}

.css-1pahdxg-control:hover {
  border-color: #3f51b5 !important;
}

.css-1pahdxg-control {
  border-color: #3f51b5 !important;
  box-shadow: 0 0 0 1px #3f51b5 !important;
}

.pagnateContainer {
  list-style: none;
  display: flex;
  margin-left: -2rem;
  margin-top: 2rem;
  justify-content: flex-end;
}

.pagnateContainer li a {
  margin: 5px;
  cursor: pointer;
  color: grey;
  padding: 10px 15px;
  border-radius: 4px;
}

.pagnateContainer li a:hover {
  background-color: lightgray;
}

.pagnateContainer li a:focus {
  outline: none !important;
}

.activeBtn a {
  background-color: #3f51b5;
  color: white !important;
}

.disabledBtn a {
  cursor: unset;
  text-decoration: none !important;
  color: rgb(155, 155, 155);
}

.navigateBtn a {
  color: rgb(0, 0, 0) !important;
}

.sscalcHeading {
  font-size: 2rem;
  font-weight: bold;
  vertical-align: middle;
}

#statistical_method .react-numeric-input {
  width: 90% !important;
}
#ISO2859 .react-numeric-input {
  width: 100% !important;
}

.lockedCard {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.lockICO {
  position: absolute;
  right: 1%;
  top: 0%;
  z-index: 100;
}

.h-90 {
  height: 90% !important;
}

.w-90 {
  width: 90% !important;
}

.hovImg:hover {
  -webkit-filter: drop-shadow(2px 4px 6px black);
          filter: drop-shadow(2px 4px 6px black);
  cursor: pointer !important  ;
}

.interpol-textarea {
  resize: none;
  /* height: 290px; */
}

textarea.form-control {
  height: 240px;
}

.custMinWidth {
  min-width: 5rem;
}

@media only screen and (max-width: 600px) {
  .custMinWidth {
    min-width: 6.5rem !important;
  }
  .earthingInputs {
    min-width: 10.5rem !important;
  }

  .dynamicOverflow {
    overflow: auto !important;
  }
}

.lockICO {
  animation: wiggle 0.1s infinite alternate;
}

@keyframes wiggle {
  0% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(10deg);
  }
}

.userFirstLetterIcon {
  border-radius: 50%;
  text-align: center;
  width: 25px;
  background-color: #959595;
  height: 25px;
}

